<template>
    <div>
        <div style="position: relative;">
            <img src="../assets/imgs/uploads/bgbrand.jpg" alt="" style="width: 100%;">
            <div class="tit">Brand Assets</div>
        </div>
        <div class="cent">
            <div class="tip">
                GiGwi Logo Download
            </div>
            <div style="margin-top: 110px;">
                <div style="width: 222px;margin-left: 270px;height: 222px;">
                    <img src="../assets/imgs/uploads/loggigwi1.png" alt="" style="width: 221px;height: 221px;">
                    <div style="margin-top: 34px;display: flex;justify-content: center;color: #000;">
                        <span @click="download('Gigwi logo png.png')">PNG</span><span class="gang">|</span><span
                            @click="download('Gigwi logo svg.svg')">SVG</span>
                    </div>
                </div>
                <div style="margin: 69px 0 58px;display: flex;justify-content: space-around;">
                    <div>
                        <div
                            style="width: 458px;height: 150px;background-color: #D9D9D9;display: flex;justify-content: center;align-items: center;">
                            <img src="../assets/imgs/uploads/loggigwi2.png" alt="" style="width: 232px;height: 66px;">
                        </div>
                        <div
                            style="margin-top: 34px;display: flex;justify-content: center;color: #000;align-items: center;">
                            <span @click="download('GiGwi.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Gigwi white svg.svg')">SVG</span>
                        </div>
                    </div>
                    <div>
                        <div style="height: 150px;display: flex;align-items: center;">
                            <img src="../assets/imgs/uploads/loggigwi3.png" alt="" style="width: 232px;height: 70px;">
                        </div>
                        <div style="margin-top: 34px;display: flex;justify-content: center;color: #000;">
                            <span @click="download('Gigwi red png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Gigwi red svg.svg')">SVG</span>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
            </div>
            <div class="tip" style="margin-top: 68px;">
                Gifts for Paws Logo Download
            </div>
            <div style="margin-top: 110px;">
                <div style="margin: 69px 0 58px;display: flex;justify-content: space-around;align-items: center;">
                    <div>
                        <div>
                            <img src="../assets/imgs/uploads/logogp1.png" alt="" style="width: 221px;height: 211px;">
                        </div>
                        <div
                            style="margin-top: 34px;display: flex;justify-content: center;color: #000;align-items: center;">
                            <span @click="download('GP logo png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('GP logo svg.svg')">SVG</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src="../assets/imgs/uploads/logogp2.png" alt="" style="width: 269px;height: 55px;">
                        </div>
                        <div style="margin-top: 34px;display: flex;justify-content: center;color: #000;">
                            <span @click="download('GP png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('GP svg.svg')">SVG</span>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
            </div>
            <div class="tip" style="margin-top: 68px;">
                PetGeek Logo Download
            </div>
            <div style="margin-top: 110px;">
                <div style="margin: 69px 0 58px;display: flex;justify-content: space-around;align-items: center;">
                    <div>
                        <div>
                            <img src="../assets/imgs/uploads/logopk1.png" alt="" style="width: 221px;height: 221px;">
                        </div>
                        <div
                            style="margin-top: 34px;display: flex;justify-content: center;color: #000;align-items: center;">
                            <span @click="download('Petgeek logo png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Petgeek logo svg.svg')">SVG</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src="../assets/imgs/uploads/logopk2.png" alt="" style="width: 269px;height: 67px;">
                        </div>
                        <div style="margin-top: 34px;display: flex;justify-content: center;color: #000;">
                            <span @click="download('Petgeek png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Petgeek svg.svg')">SVG</span>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
            </div>
            <div class="tip" style="margin-top: 68px;">
                Skipdawg Logo Download
            </div>
            <div style="margin-top: 110px;">
                <div style="margin: 69px 0 88px;display: flex;justify-content: space-around;align-items: center;">
                    <div>
                        <div style="height: 150px;display: flex;align-items: center;">
                            <img src="../assets/imgs/uploads/logosd1.png" alt="" style="width: 313px;height: 58px;">
                        </div>
                        <div style="margin-top: 34px;display: flex;justify-content: center;color: #000;">
                            <span @click="download('Skipdawg png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Skipdawg svg.svg')">SVG</span>
                        </div>
                    </div>
                    <div>
                        <div
                            style="width: 438px;height: 150px;background-color: #D9D9D9;display: flex;justify-content: center;align-items: center;">
                            <img src="../assets/imgs/uploads/logosd2.png" alt="" style="width: 313px;height: 62px;">
                        </div>
                        <div
                            style="margin-top: 34px;display: flex;justify-content: center;color: #000;align-items: center;">
                            <span @click="download('Skipdawg white png.png')">PNG</span><span class="gang">|</span><span
                                @click="download('Skipdawg white svg.svg')">SVG</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        download(res) {
            window.open(`http://35.158.1.252:9001/mall/` + res)
        },
    }
}
</script>

<style scoped>
.tit {
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    color: #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cent {
    width: 1280px;
    margin: 68px auto 0;
}

.gang {
    margin: 0 16px;
}

.tip {
    font-weight: bold;
    font-size: 24px;
    color: #000;
}

span {
    cursor: pointer;
}
</style>